import { inject, NgZone } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { AppStorageService } from '@semmie/services';
import { Observable, defer, filter, from, fromEvent, map, merge, of, switchMap, take, tap, throttleTime } from 'rxjs';
import { PlatformService } from '@semmie/services';
import { DOCUMENT } from '@angular/common';
import { UserActivityStoreActions } from './actions/user-activity-store.actions';
import { UserActivityStoreFacade } from './user-activity.facade';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { AuthFacade } from '@onyxx/store/auth';
import { filterNil } from '@onyxx/utility/observables';
import { Store } from '@ngrx/store';
import { getRouterSelectors } from '@ngrx/router-store';
import { Utils } from '@onyxx/utility/general';
import { userActivityFeature } from './user-activity.reducer';

const TIME_DIFF_MS_APP = 600_000; // 10 minutes
const TIME_DIFF_MS_WEB = 3_600_000; // 1 hour;

// due date is stored in the session so that activity can be
// tracked across browser tabs and between sessions
export const SESSION_EXPIRY_DUE_TIMESTAMP_STORAGE_KEY = 'user-activity-session-expiry-timestamp';

export class UserActivityEffects {
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly appStorageService = inject(AppStorageService);
  private readonly platformService = inject(PlatformService);
  private readonly document = inject(DOCUMENT);
  private readonly userActivityStoreFacade = inject(UserActivityStoreFacade);
  private readonly authFacade = inject(AuthFacade);
  private readonly ngZone = inject(NgZone);

  private readonly dueDateReader = this.appStorageService.createSecuredStorageReader<number>(SESSION_EXPIRY_DUE_TIMESTAMP_STORAGE_KEY);
  // eslint-disable-next-line @ngrx/avoid-mapping-selectors
  private readonly shouldMonitor$ = this.store.select(getRouterSelectors().selectRouteDataParam('monitorInactivity')).pipe(map(Boolean));

  readonly initialize$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActivityStoreActions.initialize),
      concatLatestFrom(() => this.store.select(userActivityFeature.selectReady)),
      // prevent double initialization
      filter(([, ready]) => !ready),
      switchMap(([{ skipNavigationOnLogout }]) =>
        defer(() => this.dueDateReader.get()).pipe(map((timestamp) => ({ timestamp, skipNavigationOnLogout }))),
      ),
      switchMap(({ skipNavigationOnLogout, timestamp }) => {
        if (Utils.isNotNil(timestamp) && timestamp < Date.now()) {
          // the user is inactive at the startup the app should be secured
          return this.authFacade.isAuthenticated$.pipe(
            switchMap((authenticated) => {
              if (authenticated) {
                this.authFacade.dispatchSecureApplication({ skipNavigation: skipNavigationOnLogout });
                return this.authFacade.appSecured$;
              }
              return of(true);
            }),
            filter(Boolean),
            take(1),
            map(() => UserActivityStoreActions.initializeDone({ timestamp: null })),
          );
        }

        return of(UserActivityStoreActions.initializeDone({ timestamp: timestamp }));
      }),
    );
  });

  readonly updateStore$ = createEffect(() => {
    return merge(
      this.actions$.pipe(ofType(UserActivityStoreActions.userActivity)),
      this.authFacade.appSecured$.pipe(filter(Boolean)),
      this.authFacade.isAuthenticated$.pipe(filter(Boolean)),
      this.actions$.pipe(ofType(UserActivityStoreActions.initializeDone)),
    ).pipe(
      concatLatestFrom(() => this.userActivityStoreFacade.ready$),
      filter(([, ready]) => ready),
      map(() => Date.now() + (this.platformService.isApp ? TIME_DIFF_MS_APP : TIME_DIFF_MS_WEB)),
      switchMap((dueDate) => defer(() => this.dueDateReader.set(dueDate)).pipe(map(() => dueDate))),
      map((timestamp) => UserActivityStoreActions.dueDateChanged({ timestamp })),
    );
  });

  readonly monitorExpiration$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActivityStoreActions.dueDateChanged, UserActivityStoreActions.initializeDone),
      map(({ timestamp }) => timestamp),
      filterNil(),
      // When the tab is in the background or your computer sleeps, the normal RxJs timer is paused.
      // So instead we use a "polling" timer that will check every second if the due date has passed.
      switchMap((timestamp) => this.pollingTimer(timestamp).pipe(take(1))),
      map(() => UserActivityStoreActions.sessionExpired()),
    );
  });

  readonly trackUserActivity$ = createEffect(() => {
    const userActivityChannel = new BroadcastChannel('onyxx-user-activity-channel');
    const userActivityChannelMessage$ = new Observable<'onyxx-user-activity'>((observer) => {
      userActivityChannel.onmessage = () => {
        observer.next('onyxx-user-activity');
      };
    });

    const userInteraction$ = merge(
      fromEvent(this.document, 'click', {
        passive: true,
      }),
      fromEvent(this.document, 'touchmove', {
        passive: true,
      }),
      fromEvent(this.document, 'keydown', {
        passive: true,
      }),
      fromEvent(this.document, 'wheel', {
        passive: true,
      }),
    ).pipe(throttleTime(5000, void 0, { trailing: true }));

    return merge(userInteraction$, userActivityChannelMessage$).pipe(
      tap({
        next(event) {
          if (event !== 'onyxx-user-activity') {
            // the user activity in this tab should post a message
            userActivityChannel.postMessage(void 0);
          }
        },
        complete() {
          userActivityChannel.close();
        },
      }),
      map(() => UserActivityStoreActions.userActivity()),
    );
  });

  readonly logout$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(UserActivityStoreActions.sessionExpired),
        concatLatestFrom(() => this.shouldMonitor$),
        filter(([, shouldMonitor]) => shouldMonitor),
        tap(() => {
          this.authFacade.dispatchSecureApplication();
        }),
      );
    },
    { dispatch: false },
  );

  readonly clearStorage$ = createEffect(
    () => {
      return this.authFacade.loggedOut$.pipe(
        map(() => {
          return from(this.dueDateReader.remove());
        }),
      );
    },
    { dispatch: false },
  );

  private pollingTimer(timestamp: number, pollInterval = 1000) {
    return new Observable((observer) => {
      let intervalId: ReturnType<typeof setInterval> | undefined;

      this.ngZone.runOutsideAngular(() => {
        intervalId = setInterval(() => {
          if (Date.now() >= timestamp) {
            clearInterval(intervalId);

            this.ngZone.run(() => {
              observer.next(void 0);
              observer.complete();
            });
          }
        }, pollInterval);
      });

      return () => {
        clearInterval(intervalId);
      };
    });
  }
}
