import { Route } from '@angular/router';
import { AppRouteNames } from '@onyxx/model/main';
import { IsMobileGuard } from '@semmie/guards';
import { AppRouteParameterNames } from 'apps/client-portal/src/app/app.common';

export const publicAreaRoutes: Route[] = [
  {
    path: `${AppRouteNames.Notifications}/:${AppRouteParameterNames.Id}`,
    loadComponent: () =>
      import('@semmie/views/email-notifications/email-notifications.component').then((c) => c.EmailNotificationsComponent),
  },
  {
    path: `${AppRouteNames.Invitations}/:${AppRouteParameterNames.Id}`,
    loadComponent: () => import('@semmie/views/invitation/landing/invitation-landing.component').then((c) => c.InvitationLandingComponent),
  },
  {
    path: AppRouteNames.Maintenance,
    loadComponent: () => import('@semmie/views/maintenance/maintenance.component').then((m) => m.MaintenanceComponent),
  },
  {
    path: AppRouteNames.Update,
    loadChildren: () => import('@semmie/views/update/update.module').then((m) => m.UpdateModule),
    canActivate: [IsMobileGuard],
  },
];
