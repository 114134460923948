import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { DataType, KeychainAccess, SecureStorage } from '@aparajita/capacitor-secure-storage';
import { Utils } from '@onyxx/utility/general';

export type StorageReader<T> = {
  get: () => Promise<T | null>;
  set: (data: T) => Promise<void>;
  remove: () => Promise<void>;
};

type AppStorageDataType<T> = DataType | { [P in keyof T]: unknown };

@Injectable({
  providedIn: 'root',
})
export class AppStorageService {
  /**
   * Unsecured Storage reader, using the Preferences plugin.
   */
  createStorageReader<T extends AppStorageDataType<T>>(key: string): StorageReader<T> {
    return {
      get: async () => {
        const { value } = await Preferences.get({ key });
        try {
          if (Utils.isNil(value)) return null;
          return JSON.parse(value) as T;
        } catch {
          return null;
        }
      },

      set: (data) => {
        const value = JSON.stringify(data);
        return Preferences.set({
          key,
          value,
        });
      },

      remove: () => Preferences.remove({ key }),
    };
  }

  /**
   * Secured storage reader, using the SecureStorage plugin.
   * Note: Secured storage is native only, and will not work on web.
   */
  createSecuredStorageReader<T extends AppStorageDataType<T>>(key: string): StorageReader<T> {
    return {
      get: async () => {
        const securedValue = await SecureStorage.get(key);

        /**
         * Check unsecured data as fallback in order to keep the app work as normal.
         */
        const { value } = await Preferences.get({ key });
        const unsecuredValue = await this.parseUnsecuredData<T>(value);

        // unsecured value is available. Migrate to secure storage is required.
        if (Utils.isNotNil(unsecuredValue)) {
          if (Utils.isNil(securedValue)) {
            // move the value to secure storage
            SecureStorage.set(key, unsecuredValue, undefined, undefined, KeychainAccess.afterFirstUnlock);
          }

          Preferences.remove({ key });
        }

        return (securedValue ?? unsecuredValue ?? null) as T;
      },

      // use afterFirstUnlock as recommended here https://github.com/evgenyneu/keychain-swift/issues/78
      set: (data) => SecureStorage.set(key, data, undefined, undefined, KeychainAccess.afterFirstUnlock),

      remove: () => SecureStorage.removeItem(key),
    };
  }

  private async parseUnsecuredData<T>(value: string | null): Promise<T | null> {
    try {
      if (Utils.isNil(value)) return null;
      return JSON.parse(value) as T;
    } catch {
      return null;
    }
  }
}
