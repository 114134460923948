import { Injectable } from '@angular/core';
import { ImpactStyle } from '@capacitor/haptics';
import { ToastController } from '@ionic/angular';
import { Utils } from '@onyxx/utility/general';
import { SemmieToastOptions, ToasterDuration, ToasterStyle } from '@semmie/schemas/components/toast';
import { HapticFeedbackService } from '@semmie/services/haptic-feedback/haptic-feedback.service';
import { noop } from 'rxjs';

const DEFAULT_TOAST_OPTIONS: Partial<SemmieToastOptions> = {
  position: 'top',
  color: ToasterStyle.DEFAULT,
  duration: ToasterDuration.SHORT,
  clearToasters: true,
};

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private toast: ToastController,
    private hapticFeedbackService: HapticFeedbackService,
  ) {}

  toasterIcon(options: Partial<SemmieToastOptions>) {
    if (options.hideIcon) return;
    if (options.icon) {
      return options.icon;
    }
    switch (options.style) {
      case ToasterStyle.SUCCESS:
        return 'checkmark-circle-outline';
      case ToasterStyle.WARNING:
        return 'alert-circle-outline';
      case ToasterStyle.DANGER:
        return 'close-circle-outline';
      default:
        return 'information-circle-outline';
    }
  }

  toasterButtons(options: Partial<SemmieToastOptions>) {
    const buttons = options.buttons || [];
    if (!options.hideCloseIcon) {
      buttons.push({
        text: '',
        role: 'cancel',
        icon: 'close-outline',
      });
    }
    return buttons;
  }

  toasterHapticStyle(options: Partial<SemmieToastOptions>): ImpactStyle {
    switch (options.style) {
      case ToasterStyle.SUCCESS:
      case ToasterStyle.WARNING:
        return ImpactStyle.Medium;
      case ToasterStyle.DANGER:
        return ImpactStyle.Heavy;
      default:
        return ImpactStyle.Light;
    }
  }

  async show(options: SemmieToastOptions) {
    const thisOptions = { ...DEFAULT_TOAST_OPTIONS, ...options };
    if (thisOptions.clearToasters) {
      this.clear();
    }

    const toasterOptions = {
      ...thisOptions,
      header: thisOptions.header,
      message: Utils.isString(thisOptions.message) ? thisOptions.message.toString() : options.message,
      icon: this.toasterIcon(thisOptions),
      cssClass: `onyxx-toaster onyxx-toaster-${thisOptions.style}`,
      color: undefined, // is handled by custom styling applied above
      buttons: this.toasterButtons(thisOptions),
    };

    const toast = await this.toast.create(toasterOptions);
    this.hapticFeedbackService.interact(this.toasterHapticStyle(thisOptions));
    await toast.present();
  }

  async clear() {
    // Upon dismissing we are encountering a top overlay missing error sometimes.
    // Maybe an update to ionic solves it? Requires further investigation.
    try {
      await this.toast.dismiss();
    } catch {
      // TODO: Assess why this block was empty before.
      noop();
    }
  }
}
