import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { SharedModule } from '@semmie/shared/shared.module';

import { BaseComponent } from '@semmie/components/_abstract';
import { TagTheme } from '@semmie/schemas/common/tag';

@Component({
  standalone: true,
  imports: [SharedModule],
  selector: 'semmie-tag',
  template: '<ng-container *ngIf="text">{{ text }}</ng-container>',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent extends BaseComponent {
  @Input() text: string | undefined;
  @HostBinding('class') @Input() tagTheme: TagTheme = 'dark';
}
