<ng-container *ngIf="currentStepForm">
  <ng-container [ngSwitch]="formIntroHidden">
    <ng-container *ngSwitchCase="false">
      <semmie-intro-content
        [intro]="currentStepForm.intro"
        (onButtonClick)="onIntroButtonClickHandler()"
        (onLinkLabelClick)="onIntroLinkLabelClickHandler()"
        [pageHeader]="introPageHeader"
      >
      </semmie-intro-content>
      <ng-template #introPageHeader>
        <semmie-page-header
          [leftAction]="!!this.currentStepForm.intro?.leftAction"
          (onLeftIconClick)="onIntroLeftIconClickHandler()"
          [rightAction]="this.currentStepForm.intro?.rightAction"
          (onRightIconClick)="onIntroRightIconClickHandler()"
        >
        </semmie-page-header>
      </ng-template>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <semmie-content-container size="lg" padding="px-0">
        <semmie-page-header
          *ngIf="!useModalHeader"
          [title]="!currentStepForm.useHeaderIntro ? currentStepForm.title : ''"
          [leftAction]="canStepBackwards()"
          (onLeftIconClick)="onLeftIconClickHandler()"
          [rightAction]="showCloseButton"
          (onRightIconClick)="onCloseButtonClickHandler()"
        >
          <semmie-progress-bar *ngIf="showProgress" [width]="progressionPercentage"></semmie-progress-bar>
        </semmie-page-header>
        <semmie-modal-header
          *ngIf="useModalHeader"
          [title]="currentStepForm.title"
          (onCloseClick)="onCloseButtonClickHandler()"
        ></semmie-modal-header>

        <semmie-content-container-top
          *ngIf="currentStepForm.useHeaderIntro || (currentStepForm?.description && !currentStepForm.useHeaderIntro)"
        >
          <div class="px-8" *ngIf="currentStepForm.useHeaderIntro">
            <semmie-header
              [label]="currentStepForm.label ?? ''"
              [title]="currentStepForm.title"
              [titleSize]="currentStepForm.titleSize"
              [description]="currentStepForm.description"
              [descriptionSize]="currentStepForm.descriptionSize"
            ></semmie-header>
          </div>
          <div class="px-4" *ngIf="currentStepForm.description && !currentStepForm.useHeaderIntro">
            <semmie-content-intro-text [htmlContent]="currentStepForm.description"></semmie-content-intro-text>
          </div>
        </semmie-content-container-top>

        <div class="form-container">
          <semmie-form
            #form
            [data]="data"
            [dynamicForm]="currentStepForm"
            [errors]="errors"
            [enableSideSpacing]="true"
            (onFormDataChange)="onFormDataChange($event)"
          ></semmie-form>
        </div>

        <semmie-content-container-bottom>
          <semmie-content-footer-text
            *ngIf="currentStepForm.footerText"
            [htmlContent]="currentStepForm.footerText"
          ></semmie-content-footer-text>
          <semmie-button
            [loading]="loading"
            [disabled]="submitButtonDisabled || form?.invalid || (requireChangesToSubmit && form?.pristine)"
            (onClick)="nextStepHandler()"
          >
            <span *ngIf="submitButtonText !== null; else defaultSubmitButtonText">{{ submitButtonText }} </span>
            <ng-template #defaultSubmitButtonText>
              {{ buttonText }}
            </ng-template>
          </semmie-button>
          <semmie-content-footer-link
            *ngIf="currentStepForm.linkLabel && meetsConditions(currentStepForm.linkLabel.conditions)"
            [text]="currentStepForm.linkLabel.label"
            (onClick)="onLinkLabelClick(currentStepForm.linkLabel)"
          ></semmie-content-footer-link>
        </semmie-content-container-bottom>
      </semmie-content-container>
    </ng-container>
  </ng-container>
</ng-container>
