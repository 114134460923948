<div class="info-modal-content" semmieModalStopScrollGestureOverlapDirective>
  <ng-container *ngIf="!customTemplate; else custom">
    <semmie-modal-header [hideCloseButton]="hideCloseButton" (onCloseClick)="closeModal()"></semmie-modal-header>
    <semmie-intro-content
      [title]="title"
      [label]="subtitle"
      [description]="description"
      [image]="image"
      [button]="button"
      [link_label]="linkButton"
      [footer]="footer"
      (onButtonClick)="onButtonClickHandler()"
      (onLinkLabelClick)="onLinkLabelClickHandler()"
    ></semmie-intro-content>
  </ng-container>
</div>

<ng-template #custom>
  <ng-content *ngTemplateOutlet="customTemplate"></ng-content>
</ng-template>
