/* eslint-disable @typescript-eslint/no-unused-vars */

import { Injectable, RendererFactory2, signal } from '@angular/core';

import { Subject, filter, firstValueFrom } from 'rxjs';

import { AnimationBuilder, AnimationController, ModalController, ModalOptions } from '@ionic/angular';

import { ImpactStyle } from '@capacitor/haptics';
import { Account, AccountHelpers } from '@onyxx/model/account';
import { PaymentModalOption, PaymentProviderEnum } from '@semmie/schemas/bi/payment';
import { MODAL_SIZE, ModalSize, OnyxxModalOptions } from '@semmie/schemas/components/modal';
import { ConfirmationModalParams } from '@semmie/schemas/components/modal/confirmation-modal.schema';
import { iSelectionModalOption } from '@semmie/schemas/components/modal/modal.schema';
import { HapticFeedbackService } from '@semmie/services/haptic-feedback/haptic-feedback.service';
import { PlatformService } from '@semmie/services/platform/platform.service';
import { Utils } from '@onyxx/utility/general';
import { ConfigStore } from '@semmie/store/config/config.store';

import { DEFAULT_PAYMENT_MODAL_SELECTION_OPTIONS } from '@semmie/services/modal/modal-defaults.data';
import { PaymentStoreFacade } from '@semmie/store/payment';
import { AuthFacade } from '@onyxx/store/auth';
import { UserActivityStoreFacade } from '@onyxx/store/user-activity';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

const DEFAULT_MODAL_OPTIONS: Partial<ModalOptions> = {
  handle: false,
};

const MODAL_IS_OPEN_CLASS = 'modal-is-open';
const MODAL_IS_OPENING_CLASS = 'modal-is-opening';
const MODAL_IS_CLOSING_CLASS = 'modal-is-closing';

/**
 * The ModalService that keeps track of all modal activity.
 * This service is provided in the root.
 */
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  /**
   * Stack of open modals. The last element in the list is the top most modal.
   */
  private openModals$$ = signal<Array<HTMLIonModalElement>>([]);

  /**
   * @deprecated **This is a dangerous property**
   *
   * It points to the last opened modal, which might
   * not be the one you want. Rather use the returned object from the open method.
   */
  get modal(): HTMLIonModalElement | undefined {
    const openModals = this.openModals$$();
    return openModals[openModals.length - 1];
  }

  /**
   * @deprecated **This is a dangerous property**
   *
   * This observable will emit when any open modal closes.
   * You should rather use the returned object from the open method.
   */
  onWillClose$ = new Subject();

  constructor(
    private authFacade: AuthFacade,
    private configStore: ConfigStore,
    private platformService: PlatformService,
    private modalController: ModalController,
    private hapticFeedbackService: HapticFeedbackService,
    private animationCtrl: AnimationController,
    private paymentStoreFacade: PaymentStoreFacade,
    private userActivityStoreFacade: UserActivityStoreFacade,
    private renderFactory: RendererFactory2,
  ) {
    this.checkUserInactivity();
  }

  /**
   * @param checkForSecurity wait for the app to be secure before opening the modal
   */
  async open(
    component: any,
    modalOptions: Partial<ModalOptions>,
    { size, fullSizePosition }: OnyxxModalOptions = {},
    checkForSecurity = true,
  ): Promise<HTMLIonModalElement> {
    let openAsSideSheetModal = false;

    if (this.platformService.isApp && checkForSecurity) {
      await firstValueFrom(this.authFacade.appSecured$.pipe(filter((secure) => secure === true)));
    }

    const renderer = this.renderFactory.createRenderer(null, null);
    const css = Array.isArray(modalOptions.cssClass) ? [...modalOptions.cssClass] : [modalOptions.cssClass];

    if (size === ModalSize.Auto) {
      css.push('auto-size-modal');
    } else if (size === ModalSize.Full) {
      css.push('full-size-side-modal');
      css.push(fullSizePosition === 'left' ? 'full-size-side-modal-left' : 'full-size-side-modal-right');
      const screenSizes = this.platformService.appTheme?.screens;
      const sideModalBreakpoint = Number(screenSizes?.['lg']?.replace(/\D/g, ''));
      openAsSideSheetModal = this.platformService.deviceWidth >= sideModalBreakpoint;
      if (openAsSideSheetModal) {
        modalOptions.enterAnimation = this.sideModalEnterAnimation(fullSizePosition);
        modalOptions.leaveAnimation = this.sideModalLeaveAnimation(fullSizePosition);
      }
    }

    // if ignoreDuplicate is set, prevent the same modal from being opened more than once
    const openModal = this.openModals$$().find((modal) => modal.component === component);
    if (modalOptions.componentProps?.ignoreDuplicate && openModal) {
      return openModal;
    }

    const platformModalOptions: Partial<ModalOptions> = this.platformService.isDesktop
      ? {
          canDismiss: async (data, role?: string) => {
            {
              // disable swipe to close for desktop
              // otherwise fall back to provided value or default to true
              if (role === 'gesture') {
                return false;
              }

              if (Utils.isNil(modalOptions.canDismiss)) return true;

              return typeof modalOptions.canDismiss === 'function' ? modalOptions.canDismiss(data, role) : modalOptions.canDismiss;
            }
          },
        }
      : {};

    const modal = await this.modalController.create({
      component,
      ...DEFAULT_MODAL_OPTIONS,
      ...(size && !openAsSideSheetModal ? MODAL_SIZE[size] : undefined),
      ...modalOptions,
      cssClass: css.filter(Utils.isNotNil),
      ...platformModalOptions,
    });

    this.openModals$$.update((openModals) => [...openModals, modal]);

    renderer.addClass(document.body, MODAL_IS_OPENING_CLASS);
    await modal.present();
    renderer.addClass(document.body, MODAL_IS_OPEN_CLASS);
    renderer.removeClass(document.body, MODAL_IS_OPENING_CLASS);

    modal.onWillDismiss().then((result) => {
      this.openModals$$.update((openModals) => {
        return openModals.filter((m) => m !== modal);
      });
      this.onWillClose$.next(result.data);
      if (this.openModals$$().length === 0) {
        renderer.removeClass(document.body, MODAL_IS_OPEN_CLASS);
        renderer.addClass(document.body, MODAL_IS_CLOSING_CLASS);
      }
    });

    modal.onDidDismiss().then(() => {
      if (this.openModals$$().length === 0) {
        renderer.removeClass(document.body, MODAL_IS_CLOSING_CLASS);
      }
    });

    return modal;
  }

  /**
   * @deprecated **This function is dangerous**.
   *
   * This method It will close the last opened modal, and there is no
   * guarantee that it is the intended modal.
   */
  close(result?: any) {
    return this.modalController.dismiss(result);
  }

  async openSelectionModal(
    content: { title?: string; description?: string },
    size: ModalSize,
    options: Array<iSelectionModalOption> | ReadonlyArray<iSelectionModalOption>,
    selectionOptions?: { mode?: 'fixed' | 'scrollable'; search?: boolean },
  ) {
    const css = ['selection-modal', size === ModalSize.Auto ? 'auto-size-modal' : 'full-size-side-modal'];

    const { SelectionModalComponent } = await import('@semmie/components/containers/modals/selection-modal/selection-modal.component');
    const modalOptions = Object.assign({}, MODAL_SIZE[size], {
      cssClass: css.join(' '),
      componentProps: { ...content, options, ...selectionOptions },
      handle: false,
    });

    return this.open(SelectionModalComponent, modalOptions);
  }

  async openRichSelectionModal(title: string, description: string, size: ModalSize, options: Array<iSelectionModalOption>) {
    const css = ['selection-modal', size === ModalSize.Auto ? 'auto-size-modal' : ''];

    const { SelectionModalComponent } = await import('@semmie/components/containers/modals/selection-modal/selection-modal.component');
    const modalOptions = Object.assign({}, MODAL_SIZE[size], {
      cssClass: css.join(' '),
      componentProps: { title, description, options },
      handle: false,
    });

    return this.open(SelectionModalComponent, modalOptions);
  }

  async openPaymentOptionsModal(
    customOptions?: PaymentProviderEnum[],
    prefill?: { amount?: number; deposit?: number },
    account?: Account,
  ): Promise<PaymentModalOption> {
    const configData = this.configStore.getViewConfig<(typeof DEFAULT_PAYMENT_MODAL_SELECTION_OPTIONS)[number]>(
      'account.overview.deposit.payment_options',
    );

    let options = DEFAULT_PAYMENT_MODAL_SELECTION_OPTIONS.map((option) => {
      if (configData?.[option.id]) {
        return {
          ...option,
          ...configData[option.id],
        };
      }

      return option;
    }).map((option) => {
      switch (option.id) {
        case PaymentProviderEnum.ideal:
          return {
            ...option,
            label: $localize`:@@payment.deposit-modal.options.ideal.label:iDEAL`,
            description: option.disabled
              ? $localize`:@@payment.deposit-modal.options.ideal.description-disabled:Depositing with iDEAL is (temporarily) disabled.`
              : $localize`:@@payment.deposit-modal.options.ideal.description-active:Use iDEAL to deposit easily and quickly via your own banking environment.`,
          };
        case PaymentProviderEnum.bancontact:
          return {
            ...option,
            label: $localize`:@@payment.deposit-modal.options.bancontact.label:Bancontact`,
            description: option.disabled
              ? $localize`:@@payment.deposit-modal.options.bancontact.description-disabled:Depositing with Bancontact is (temporarily) disabled.`
              : $localize`:@@payment.deposit-modal.options.bancontact.description-active:Belgian bank account? Deposit via your own banking environment with Bancontact`,
          };
        case PaymentProviderEnum.manual: {
          if (
            Utils.isNotNil(account) &&
            !AccountHelpers.isCreated(account) &&
            (Utils.isNotNil(account.number) || Utils.isNotNil(account.iban))
          ) {
            return {
              ...option,
              disabled: true,
              label: $localize`:@@payment.deposit-modal.options.manual.label:Direct transfer`,
              description: $localize`:@@payment.deposit-modal.options.manual.description-inactive:Direct transfers will be available once your account is activated.`,
            };
          }

          return {
            ...option,
            label: $localize`:@@payment.deposit-modal.options.manual.label:Direct transfer`,
            description: option.disabled
              ? $localize`:@@payment.deposit-modal.options.manual.description-disabled:Direct transfers are (temporarily) disabled.`
              : $localize`:@@payment.deposit-modal.options.manual.description-active:Direct transfer via your own banking environment.`,
          };
        }
        case PaymentProviderEnum.directDebit:
          return {
            ...option,
            label: $localize`:@@payment.deposit-modal.options.direct-debit.label:Direct debit`,
            description: option.disabled
              ? $localize`:@@payment.deposit-modal.options.directDebit.description-disabled:Direct debits are (temporarily) disabled.`
              : $localize`:@@payment.deposit-modal.options.directDebit.description-active:Set up automatic deposits from your bank to Semmie.`,
          };
      }
    });

    if (customOptions) {
      options = options.filter((o) => customOptions.includes(o.id as PaymentProviderEnum));
    }

    const modal = await this.openSelectionModal(
      {
        title: $localize`:@@payment.deposit-modal.title:Deposit`,
        description: $localize`:@@payment.deposit-modal.description:<strong>Important:</strong> you can only deposit money into your Semmie account from a bank account where you are listed as the account holder`,
      },
      ModalSize.Auto,
      options,
    );

    const { data: result } = await modal.onDidDismiss<any>();

    if (result?.id && !result?.route) {
      this.openPaymentDialog(result.id, { withForm: true, prefill });
    }

    return Promise.resolve(result);
  }

  async openPaymentDialog(
    context: PaymentProviderEnum,
    {
      withForm,
      prefill,
      source,
      accountId,
    }: {
      withForm?: boolean;
      prefill?: { amount?: number; deposit?: number };
      source?: PaymentProviderEnum;
      accountId?: string;
    } = {},
  ): Promise<void> {
    const { PaymentModalComponent } = await import('@semmie/components/containers/modals/payment-modal/payment-modal.component');

    const modalOptions = Object.assign({}, null, {
      cssClass: `${withForm ? 'form-modal auto-size-modal' : 'auto-size-modal'} android-keyboard-support`,
      componentProps: {
        prefillAmount: prefill?.amount,
        accountId,
        modalData: {
          option: context,
          source,
        },
      },
    });

    const modal = await this.open(PaymentModalComponent, modalOptions, { size: ModalSize.Auto });
    modal.onWillDismiss().then(() => {
      // TODO: When the payment store is implemented, this event can be replaced
      this.paymentStoreFacade.paymentDone();
    });
  }

  async openConfirmationModal({ canDismiss, title, description, ...options }: ConfirmationModalParams) {
    const { ConfirmationModalComponent } = await import(
      '@semmie/components/containers/modals/confirmation-modal/confirmation-modal.component'
    );

    const modalOptions = Object.assign({}, MODAL_SIZE[ModalSize.Auto], {
      cssClass: 'selection-modal auto-size-modal',
      componentProps: { title, description, options },
      backdropDismiss: canDismiss ?? true,
      canDismiss: canDismiss ?? true,
      handle: false,
    });
    this.hapticFeedbackService.interact(ImpactStyle.Light);
    return this.open(ConfirmationModalComponent, modalOptions);
  }

  async openAdvisorModal() {
    const { AdvisorComponent } = await import('@semmie/views/settings/advisor/advisor.component');
    await this.open(AdvisorComponent, {}, { size: ModalSize.Full });
  }

  private sideModalEnterAnimation =
    (position: OnyxxModalOptions['fullSizePosition']): AnimationBuilder =>
    (baseEl: HTMLElement) => {
      const root = baseEl.shadowRoot;

      const backdropEl = root?.querySelector('ion-backdrop');
      const modalWrapperEl = root?.querySelectorAll('.modal-wrapper, .modal-shadow');

      if (!backdropEl || !modalWrapperEl) return this.animationCtrl.create();

      const backdropAnimation = this.animationCtrl.create().addElement(backdropEl).fromTo('opacity', '0.01', 'var(--backdrop-opacity)');

      const keyFramesFromLeft = [
        { offset: 0, opacity: '1', transform: 'translateX(-100%)', position: 'absolute', left: 0 },
        { offset: 1, opacity: '1', transform: 'translateX(0)', position: 'absolute', left: 0 },
      ];
      const keyFramesFromRight = [
        { offset: 0, opacity: '1', transform: 'translateX(100%)', position: 'absolute', right: 0 },
        { offset: 1, opacity: '1', transform: 'translateX(0)', position: 'absolute', right: 0 },
      ];
      const wrapperAnimation = this.animationCtrl
        .create()
        .addElement(modalWrapperEl)
        .keyframes(position === 'left' ? keyFramesFromLeft : keyFramesFromRight);

      return this.animationCtrl
        .create()
        .addElement(baseEl)
        .easing('ease-out')
        .duration(200)
        .addAnimation([backdropAnimation, wrapperAnimation]);
    };

  private sideModalLeaveAnimation =
    (position: OnyxxModalOptions['fullSizePosition']): AnimationBuilder =>
    (baseEl: HTMLElement, options) => {
      return this.sideModalEnterAnimation(position)(baseEl, options)?.direction('reverse');
    };

  /**
   * Check if the user is inactive and dismiss open modal if need to.
   * * On web all modals should dismiss when logged out, otherwise it will still shown at the login page.
   */
  private async checkUserInactivity() {
    if (this.platformService.isWeb) {
      this.authFacade.loggedOut$.pipe(takeUntilDestroyed()).subscribe(() => {
        this.dismissOpenModals();
        this.dismissOpenIonModals();
      });
    } else if (this.platformService.isApp) {
      this.userActivityStoreFacade.sessionExpired$.pipe(takeUntilDestroyed()).subscribe((sessionExpired) => {
        if (sessionExpired) {
          this.dismissOpenModals();
        }
      });
    }
  }

  /**
   * Dismiss (open) modal(s):
   * * Modals with canDismiss disabled should be enabled again first in order to close. (e.g. scanner modal)
   */
  private dismissOpenModals() {
    this.openModals$$().forEach((modal) => {
      if (this.platformService.isApp && !modal.componentProps?.dismissAtInactivity) return;
      modal.canDismiss = true;
      modal.dismiss();
    });
  }

  /**
   * Dismiss all inline modals (ion-modal) that aren't opened by the modal service
   */
  private async dismissOpenIonModals() {
    // Dev note: `getTop` is only way to check if a modal is opened
    const isModalOpened = await this.modalController.getTop();
    if (Utils.isNotNil(isModalOpened)) {
      await this.modalController.dismiss();
    }
  }
}
