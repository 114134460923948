import { Injectable } from '@angular/core';

import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { distinctUntilChanged, filter, map, startWith, switchMap, take } from 'rxjs/operators';

import { Task } from '@semmie/models/bi/task';

import { Person } from '@semmie/models/bi/person';
import { FormPageComponent } from '@semmie/components/containers/form-page/form-page.component';
import { EventData, EventDataCategory } from '@semmie/schemas/bi/event/event.schema';
import { EventsProvider } from '@semmie/providers/events/events.provider';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
import { PlatformService } from '@semmie/services/platform/platform.service';
import { environment } from 'environments/environment';
import { Account } from '@onyxx/model/account';
import { MagazineArticle } from '@onyxx/model/magazine';
import { Utils } from '@onyxx/utility/general';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  readonly UNAUTHORIZED_MODULES = ['login', 'register', 'update', 'set-password'];

  constructor(
    private platformService: PlatformService,
    private eventsProvider: EventsProvider,
  ) {}

  post(event: EventData) {
    this.postAnalyticsEvent(event);

    return this.eventsProvider.post(event).pipe(take(1));
  }

  postAnalyticsEvent(event: EventData) {
    if (this.platformService.isApp && event.category && event.name && environment.production) {
      if (event.category === EventDataCategory.Navigation) {
        FirebaseAnalytics.setCurrentScreen({
          screenName: event.name,
        });
      }

      let categoryName = `semmie_${event.category}`;
      let paramsName = event.name;

      if (event.category === EventDataCategory.System && (event.name.includes('login') || event.name.includes('sign_up'))) {
        categoryName = event.name.includes('login') ? 'login' : 'sign_up';
        paramsName = event.name.replace('login_', '').replace('signup_', '');
      }

      FirebaseAnalytics.logEvent({
        name: categoryName,
        params: {
          name: paramsName,
        },
      });
    }
  }

  remapEvent(componentName: string | null, context?: any) {
    if (!componentName) {
      return EMPTY;
    }

    const url = window.location.href;
    const cleansedName = componentName.replace('semmie-', '');

    if (this.UNAUTHORIZED_MODULES.includes(cleansedName)) return EMPTY;

    const eventData = <EventData>{
      name: cleansedName,
      category: EventDataCategory.Navigation,
    };

    if (cleansedName === 'onboarding') {
      return this.remapOnboardingEvent(cleansedName, context);
    } else if (cleansedName === 'accounts') {
      eventData.name = 'homescreen';
    } else if (cleansedName.startsWith('account-')) {
      return this.remapAccountEvent(cleansedName, context);
    } else if (cleansedName === 'settings-profile') {
      if (url.includes('accounts')) {
        return this.remapAccountEvent(cleansedName, context);
      } else if (url.includes('cdd')) {
        eventData.name = 'critical_task-person-details';
      } else {
        eventData.name = 'settings-person-details';
      }
    } else if (cleansedName === 'settings-investor-profile') {
      return this.remapQuestionnaireEvent(cleansedName, context);
    } else if (cleansedName === 'invitation') {
      return this.remapInvitationEvent(cleansedName, context);
    } else if (cleansedName === 'settings-display-sort') {
      eventData.name = 'settings-account-sorting';
    } else if (cleansedName === 'fund-details') {
      eventData.name = `account-fundcard-${context?.instrument?.id}`;
    } else if (cleansedName === 'settings-security-phone-verify' && url.includes('onboarding')) {
      if (url.includes('grandparent')) {
        eventData.name = 'create-account-grandparent-code-verification';
      } else {
        eventData.name = 'create-person-code-verification';
      }

      return combineLatest([context?.account$ as Observable<Account>, context?.person$ as Observable<Person>]).pipe(
        take(1),
        map(([account, person]) => {
          if (account) eventData.account_id = account.id;
          if (person) eventData.person_id = person.id;

          return eventData;
        }),
      );
    } else if (cleansedName === 'magazine-item') {
      // article signal in `magazine-detail.component.ts`
      const article: MagazineArticle | undefined = context.article$$();
      if (Utils.isNotNil(article)) {
        eventData.data = JSON.stringify({ id: article.id });
      }
    } else if (cleansedName === 'settings-task') {
      return this.remapTaskEvent(cleansedName, context);
    }

    return of(eventData);
  }

  remapTaskEvent(componentName: string, context: any): Observable<EventData> {
    return context?.task$?.pipe(
      take(1),
      map((task: Task) => {
        return <EventData>{
          name: componentName,
          category: EventDataCategory.Navigation,
          data: task?.message?.title ?? '',
        };
      }),
    );
  }

  remapAccountEvent(componentName: string, context: any): Observable<EventData | null> {
    if (componentName === 'account-plan') {
      return this.remapOnboardingEvent(componentName, context);
    }

    if (componentName === 'settings-profile') {
      componentName = 'account-account-holder';

      return combineLatest([context?.account$ as Observable<Account>, context?.person$ as Observable<Person>]).pipe(
        take(1),
        map(([account, person]) => {
          return <EventData>{
            name: componentName,
            category: EventDataCategory.Navigation,
            account_id: account?.id,
            person_id: person?.id,
          };
        }),
      );
    }

    return context?.account$?.pipe(
      take(1),
      map((account: Account) => {
        return <EventData>{
          name: componentName,
          category: EventDataCategory.Navigation,
          account_id: account?.id,
        };
      }),
    );
  }

  remapInvitationEvent(componentName: string, context: any): Observable<EventData> {
    return context?.account$?.pipe(
      take(1),
      map((account: Account) => {
        return <EventData>{
          name: 'create-account-accept-invite-intro',
          category: EventDataCategory.Navigation,
          account_id: account?.id,
        };
      }),
    );
  }

  remapOnboardingEvent(componentName: string, context: any): Observable<EventData | null> {
    let event: string[] = [];
    const url = window.location.href;

    /**
     * Some steps in the onboarding don't use the form (yet), so explicitly handle those situations.
     */
    if (!context?.steps && !context?.steps$) {
      if (url.includes('onboarding')) {
        event.push('create');

        if (url.includes('strategy')) {
          event.push('account-strategy');
        } else if (url.includes('sign')) {
          event.push('account-signing-initiate');
        }

        if (context.account$) {
          return context.account$.pipe(
            take(1),
            map((account: Account) => {
              return <EventData>{
                name: event.filter((e) => !!e).join('-'),
                category: EventDataCategory.Navigation,
                account_id: account?.id,
              };
            }),
          );
        }

        return of(<EventData>{
          name: event.filter((e) => !!e).join('-'),
          category: EventDataCategory.Navigation,
        });
      }

      return of(<EventData>{
        name: componentName,
        category: EventDataCategory.Navigation,
      });
    }

    if (context?.formPageList) {
      return context?.formPageList?.changes?.pipe(
        map((changes: any) => changes.last),
        startWith(context?.formPage),
        filter((f) => !!f),
        switchMap((formPage: FormPageComponent) =>
          combineLatest([of(formPage), formPage.stepReference$$, context?.account$ || of(null), context?.person$ || of(null)]),
        ),
        distinctUntilChanged(([, stepLeft], [, stepRight]) => stepLeft === stepRight),
        map(([formPage, stepReference, account, person]) => {
          event = [];

          if (!stepReference) return null;

          if (url.includes('onboarding')) {
            if (!url.includes('payment')) {
              event.push('create');
            }
          } else if (url.includes('account')) {
            event.push('update');
          }

          const step = formPage?.formSteps?.filter((s) => s.reference === stepReference)[0];
          event.push(step?.legacyEventId);

          const eventData: EventData = {
            name: event.filter((e) => !!e).join('-'),
            category: EventDataCategory.Navigation,
          };

          if (account) eventData.account_id = account.id;
          if (person) eventData.person_id = person.id;

          return eventData;
        }),
      );
    }

    return of(null);
  }

  remapQuestionnaireEvent(componentName: string, context: any): Observable<EventData> | undefined {
    let event: string[] = [];

    /**
     * Some steps in the onboarding don't use the form (yet), so explicitly handle those situations.
     */
    if (!context?.steps && !context?.steps$) {
      return of(<EventData>{
        name: componentName,
        category: EventDataCategory.Navigation,
      });
    }

    if (context?.formPageList) {
      return context?.formPageList?.changes?.pipe(
        map((changes: any) => changes.last),
        startWith(context?.formPage),
        filter((f) => !!f),
        switchMap((formPage: FormPageComponent) => combineLatest([of(formPage), formPage.stepReference$$])),
        distinctUntilChanged(([, stepLeft], [, stepRight]) => {
          return stepLeft === stepRight;
        }),
        map(([formPage, stepReference]) => {
          event = [];

          if (!stepReference) return null;

          const step = formPage?.formSteps?.filter((s) => s.reference === stepReference)[0];
          event.push(step?.legacyEventId);

          const eventData: EventData = {
            name: event.filter((e) => !!e).join('-'),
            category: EventDataCategory.Navigation,
          };

          return eventData;
        }),
      );
    }

    of(null);
  }
}
