import { Inject, Injectable } from '@angular/core';
import { APP_BASE_HREF } from '@angular/common';
import { Platform } from '@ionic/angular';
import { default as capConfig } from '../../../../capacitor.config';
import { SafeArea, SafeAreaController } from '@aashu-dubey/capacitor-statusbar-safe-area';
import { AndroidFullScreen } from '@awesome-cordova-plugins/android-full-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Keyboard, KeyboardStyle } from '@capacitor/keyboard';
import { Utils as LegacyUtils } from '@semmie/shared/utils';
import { map, shareReplay, startWith } from 'rxjs/operators';
import { theme } from 'tailwindcss/defaultConfig';
import { OnyxxPlatform } from '@semmie/schemas/common/platform';
import { Capacitor } from '@capacitor/core';
import { environment } from 'environments/environment';
import { AppTheme } from '@semmie/schemas';
import { Utils } from '@onyxx/utility/general';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  readonly appTheme = theme;

  readonly size = this.platform.resize.pipe(
    startWith({ width: this.platform.width(), height: this.platform.height() }),
    map(() => ({ width: this.platform.width(), height: this.platform.height() })),
    shareReplay({ bufferSize: 1, refCount: true }),
  );

  readonly lgScreenWidthViewPort = parseInt(this.appTheme?.screens?.['lg'].replace('px', ''));
  readonly mdScreenWidthViewPort = parseInt(this.appTheme?.screens?.['md'].replace('px', ''));
  readonly smScreenWidthViewPort = parseInt(this.appTheme?.screens?.['sm'].replace('px', ''));

  readonly appLayout$ = this.size.pipe(map((size) => size.width < this.lgScreenWidthViewPort));
  readonly smLayout$ = this.size.pipe(map((size) => size.width < this.mdScreenWidthViewPort));

  private readonly capacitorConfig = capConfig as any;

  constructor(
    @Inject(APP_BASE_HREF) public baseHref: string,
    private platform: Platform,
  ) {
    this.platform.ready().then(() => {
      /**
       * !todo: handle android intents (for deeplinking later)
       */
      if (this.isApp) {
        this.setStatusBarStyle(Style.Light);
        this.setKeyboardStyle(KeyboardStyle.Light);
      }
      this.handleSafeAreas();
    });
  }

  get isApp() {
    return this.isRunningOn('app');
  }

  get isWeb() {
    return this.isRunningOn('web');
  }

  get isDesktop() {
    return this.isRunningOn('desktop');
  }

  /**
   * Check if the app is running on an iOS device
   * - Returns also `true` on iPhone mobile web
   */
  get isiOS() {
    return this.isRunningOn('ios');
  }

  /**
   * Check if the app is running on an Android device
   * - Returns also `true` on android mobile web
   */
  get isAndroid() {
    return this.isRunningOn('android');
  }

  get isTablet() {
    return this.isRunningOn('tablet');
  }

  get isMobile() {
    return this.isRunningOn('mobile');
  }

  get deviceWidth() {
    return this.platform.width();
  }

  get deviceHeight() {
    return this.platform.height();
  }

  get isMdLayout() {
    return this.deviceWidth < this.lgScreenWidthViewPort;
  }

  async getStatusBarHeight() {
    return await SafeArea.getStatusBarHeight();
  }

  async getSafeAreaInsets() {
    return await SafeArea.getSafeAreaInsets();
  }

  async handleSafeAreas() {
    SafeAreaController.injectCSSVariables();
    if (!this.isApp) return;
    const statusbar = await this.getStatusBarHeight();
    const insets = await this.getSafeAreaInsets();

    if (insets && this.isAndroid) {
      if (insets.bottom > 0) {
        // Extend your app underneath the system UI (Android 4.4+ only)
        AndroidFullScreen.showUnderSystemUI();
      }

      if (insets.top === 0 && statusbar && statusbar.height > 0) {
        const style = document.documentElement.style;
        style.setProperty('--safe-area-inset-top', statusbar.height + 'px');
      } else {
        // Extend your app underneath the status bar (Android 4.4+ only)
        AndroidFullScreen.showUnderStatusBar();
      }
    }
  }

  setAppStyles(theme: AppTheme) {
    if (theme === AppTheme.DARK) {
      this.setStatusBarStyle(Style.Dark);
      this.setKeyboardStyle(KeyboardStyle.Dark);
    } else {
      this.setStatusBarStyle(Style.Light);
      this.setKeyboardStyle(KeyboardStyle.Light);
    }
  }

  setStatusBarStyle(style: Style) {
    if (this.isiOS && this.isApp) {
      StatusBar.setStyle({ style: style });
    }
  }

  setKeyboardStyle(style: KeyboardStyle) {
    if (this.isiOS && this.isApp) {
      Keyboard.setStyle({ style: style });
    }
  }

  isRunningOn(platform: OnyxxPlatform): boolean {
    switch (platform) {
      case 'web':
        return this.platform.is('desktop') || this.platform.is('mobileweb');
      case 'desktop':
        // Mobile as extra check because android emulator returns true
        return this.platform.is('desktop') && !this.isRunningOn('mobile');
      case 'android':
        return this.platform.is('android');
      case 'ios':
        return this.platform.is('ios');
      case 'tablet':
        return this.platform.is('tablet') || this.platform.is('ipad');
      case 'app':
        return Capacitor.isNativePlatform();
      case 'mobile':
        return this.platform.is('android') || this.platform.is('ios') || this.platform.is('mobileweb');
      default:
        return false;
    }
  }

  getPlatform(): 'ios' | 'android' | 'web' {
    if (this.platform.is('ios') && !this.isRunningOn('web')) {
      return 'ios';
    } else if (this.platform.is('android') && !this.isRunningOn('web')) {
      return 'android';
    } else {
      return 'web';
    }
  }

  resolveAssetPath(path: string, options: { darkTheme?: boolean; premium?: boolean } = {}): string {
    const pathParts = path.split('/');

    if (!pathParts.includes('assets')) {
      pathParts.unshift('assets');
    }

    if (options.premium) {
      pathParts.splice(1, 0, 'premium');
    }

    if (options.darkTheme) {
      pathParts.splice(1, 0, 'dark-theme');
    }

    return pathParts.join('/');
  }

  osVersion(): number {
    // window.navigator.oscpu
    return Number.parseFloat('0.0');
  }

  getAppDeepLink(path: string, params?: string): string {
    const baseHref = this.baseHref ?? '/';
    // TODO: Find the cause for the unknown amount of / that are prefixed sometimes
    const normalizedPath = Utils.recursivelyStripPrefix('/', Utils.recursivelyStripPrefix(baseHref, path)) ?? '';
    const normalizedParams = params?.startsWith('/') ? params : params ? `/${params}` : '';

    /**
     * !todo: get url from config
     */
    if (!this.isRunningOn('app')) {
      return `${LegacyUtils.baseUrl()}${baseHref}${normalizedPath}${normalizedParams}`;
    } else if (this.isRunningOn('android')) {
      // TODO: investigate if we could use only the semmie:// scheme as the host denoted the environment already, same has to be done for iOS
      return `${environment.androidIntentScheme}://${environment.universalLink}/${normalizedPath}${normalizedParams}`;
    } else if (this.isRunningOn('ios')) {
      const appScheme = this.capacitorConfig.server.iosScheme;
      return `${appScheme}://${normalizedPath}${normalizedParams}`;
    }

    return `${LegacyUtils.baseUrl()}/${normalizedPath}${normalizedParams}`;
  }
}
